<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      style="background-color: #00b0ff;border: none;"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg svg-icon-white">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text text-white">Daftar <b>User</b></div>
      <!-- <b-button
        squared
        variant="primary"
        @click="$router.push('/masters/users/add')"
        v-if="btnAccess"
        >Tambah</b-button
      > -->
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs content-class="mt-3">
              <b-tab title="Lihat Semua" active @click="filterByRole(-1)">
              </b-tab>
              <div v-for="role in roles" :key="role.id">
              <b-tab :title="role.name" @click="filterByRole(role.id)">
              </b-tab>
              </div>
            </b-tabs>
            <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '25%' : '' }"
                  />
                </template>

                <!-- <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/cadre/detail/' + data.item.id,
                      })
                    "
                    ><i class="fas fa-eye px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="
                      $router.push({
                        path: '/cadre/edit/' + data.item.id,
                      })
                    "
                    v-if="btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="btnAccess"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </template> -->
              </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import Table from "@/view/components/users/Table.vue";

export default {
  name: "master-users",
  components: {
    Table,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        role_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "posyandu_name",
          label: "Posyandu",
          sortable: true,
        },
        {
          key: "registration_number",
          label: "NIK",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "role_name",
          label: "Peran",
          sortable: true,
        },
        {
          key: "phone",
          label: "Telepon",
          sortable: true,
        },
        // {
        //   key: "action",
        //   label: "Aksi",
        //   tdClass: "nowrap"
        // },
      ],
      items: [],
      roles: [],
      role: "",
      // access
      btnAccess: true
    };
  },
  methods: {
    filterByRole(roleId) {
      if(roleId == -1){
        this.filter.role_id = ''
      }else{
        this.filter.role_id = roleId
      }

      this.pagination()
    },
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&role_id=${this.filter.role_id}`;
      let response = await module.paginate(
        "api/users",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getRoles() {
      let response = await module.paginate("api/roles/select/user");
      this.roles = response.data
    },

    filterByName() {
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/users/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "User" },
    ]);
    this.pagination();
    this.getRoles();
    this.checkAccess();
    this.filter.role_id = this.$route.params.id
  },
  beforeRouteUpdate(to, from, next) {
    // this.getContent(to.params.uid);
    this.filter.role_id = to.params.id;
    //("ketekan")
    next();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>